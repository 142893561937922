import {GenericError} from '@core/api';
import {Config} from '@core/configuration';
import Registry from '@core/registry';
import {doAuthorizeUser, doLogout, startTokenExpirationTimer} from '../Authentication';
import {AuthenticationEvents} from '../constants';
import {AccessTokenUtils} from './AccessTokenUtils';

const retrieveTokenOrRedirectToLogin = (queryParams: URLSearchParams) =>
    new Promise((resolve, reject) => {
        // Detect login and retrieve access token.
        const accessTokenFromQueryParams = queryParams.get('access_token');
        const accessTokenFromStorage = AccessTokenUtils.getToken();
        const accessTokenExists = !!accessTokenFromQueryParams || !!accessTokenFromStorage;

        // if not logged in or needs a new session, redirect to login page
        if (!accessTokenExists || window.location.href.includes('newsession')) {
            doAuthorizeUser();
            reject();
        } else {
            // if logged in, retrieve access token from query params or local storage
            const expiresIn = parseInt(queryParams.get('expires_in') ?? '0', 10);

            // Access token could be there twice in the URL, make sure we use only the first.
            const decodedAccessToken = Array.isArray(accessTokenFromQueryParams)
                ? accessTokenFromQueryParams[0]
                : accessTokenFromQueryParams;

            if (!!decodedAccessToken) {
                AccessTokenUtils.setToken(decodedAccessToken, expiresIn);
            }

            const accessToken = decodedAccessToken || accessTokenFromStorage;
            // register the access token in the registry
            Registry.register('access_token', accessToken);
            Registry.register('organization_id', ''); // required to use Platform

            if (accessTokenFromQueryParams) {
                // remove the access token from the query params
                const originalRouteParts = window.location.href.split('#');

                let queryString = '';

                if (originalRouteParts.length > 1) {
                    const lastPart = originalRouteParts[originalRouteParts.length - 1];

                    if (lastPart) {
                        queryParams.delete('access_token');
                        queryParams.delete('expires_in');
                        queryParams.delete('loggerId');
                        queryParams.delete('scope');
                        queryParams.delete('token_type');
                        queryString = queryParams.toString();

                        originalRouteParts.pop();
                    }

                    window.location.replace(`${originalRouteParts.join('#')}#${decodeURIComponent(queryString)}`);
                }
            }

            // validate that the token exists and hasn't expired
            AccessTokenUtils.checkToken()
                // eslint-disable-next-line @helpers/no-then-catch-finally
                .then((valid) => {
                    if (valid) {
                        resolve(true);
                    } else {
                        doAuthorizeUser();
                        reject();
                    }
                });
        }
    });

type ErrorHandlerFunction = (error: GenericError) => void;
const prepareApp = async (errorHandler: ErrorHandlerFunction) => {
    document.addEventListener(AuthenticationEvents.FullLogout, doLogout);
    document.addEventListener(AuthenticationEvents.UserNeedsAuthorization, doAuthorizeUser);

    // URI can only parse real query params. Ours are in the hash, so we need to replace the # by a ? to make it work.
    const queryParams = new URLSearchParams(window.location.hash.replace(/^#/, '?'));

    try {
        await retrieveTokenOrRedirectToLogin(queryParams);
        startTokenExpirationTimer();
        Registry.register('configuration', Config);
        Registry.register('error_handler', errorHandler);
    } catch {
        // The different fetch calls handle their own errors, and redirect to an error page if needed.
    }
};

export const AuthenticationUtils = {
    prepareApp,
};
