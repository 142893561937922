import {notifications} from '@components/mantine';
import {SENTRY_DSN} from '@configurations/platform';
import {GenericError} from '@core/api';
import {AuthenticationUtils} from '@core/authentication';
import {Config} from '@core/configuration';
import {createRoutesFromChildren, matchRoutes, useLocation, useNavigationType} from '@core/routes';
import {commonLocales} from '@coveord/jsadmin-common';
import {Defaults as ReactVaporDefaults} from '@coveord/plasma-react';
import {init, reactRouterV6BrowserTracingIntegration} from '@sentry/react';
import editorWorker from 'monaco-editor/esm/vs/editor/editor.worker?worker';
import jsonWorker from 'monaco-editor/esm/vs/language/json/json.worker?worker';
import {useEffect} from 'react';
import {createRoot} from 'react-dom/client';
import {MainApplication} from '../applications';
import {Locales} from '../strings/Locales';

// Reset window.name value to prevent bugs when navigating to search results...
window.name = '';

Backbone.Syphon.InputReaders.register('default', (a) => {
    const t = a.val();
    const n = parseFloat(t);

    return isNaN(n) || typeof n !== 'number' || n.toString() !== t ? t : n;
});

/**
 * ReactRouter forces us to start our URLs with a `/`. This method simply add the starting slash if it's not there
 */
const addStartingSlashToHash = () => {
    const hash = window.location.hash;
    if (/^#[^\/]/.test(hash)) {
        window.location.hash = hash.replace(/^#(.+)/, '#/$1');
    }
};

addStartingSlashToHash();

const initializeSentry = () => {
    if (
        !['localhost', 'local.cloud.coveo.com'].includes(window.location.hostname) // avoid sending errors from local server
    ) {
        try {
            init({
                dsn: SENTRY_DSN,
                environment: Config.envShort,
                release: __APP_VERSION__,
                integrations: [
                    reactRouterV6BrowserTracingIntegration({
                        useEffect,
                        useLocation,
                        useNavigationType,
                        createRoutesFromChildren,
                        matchRoutes,
                    }),
                ],
                beforeSend: (event) => {
                    if (
                        /^Unable to preload CSS for \/admin\/.+\/assets\/.+\.css/.test(event.exception.values[0].value)
                    ) {
                        event.fingerprint = ['preload-css-error'];
                    }
                    return event;
                },
                tracesSampleRate: 0.2,
                maxBreadcrumbs: 50,
                autoSessionTracking: true,
                attachStacktrace: true,
                ignoreErrors: [
                    'Non-Error exception captured',
                    'Non-Error promise rejection captured',
                    'TypeError: Failed to fetch',
                    'TypeError: NetworkError when attempting to fetch resource.',
                    'AbortError',
                    'HTTP/1.1 Overhead',
                    'UnhandledRejection: Object captured as promise rejection with keys: errorCode, message, requestID',
                    'Canceled: Canceled',
                ],
                denyUrls: [/extension:\/\//i],
            });
        } catch (e) {
            // This is a fail-safe to make sure that if Sentry throws an exception, the admin still starts.
            console.error(`Sentry could not be initialized. Exception won't be logged in Sentry`);
        }
    }
};

const initializeApp = async () => {
    initializeSentry();
    const errorHandler = (error: GenericError) => {
        notifications.showError(
            commonLocales.formatOrDefault(error.errorCode, {
                defaultTranslation: error.message ?? Locales.format('UNKNOWN_ERROR'),
            }),
            error,
        );
    };
    return AuthenticationUtils.prepareApp(errorHandler);
};

const renderApp = () => {
    const root = createRoot(document.querySelector('.application-wrapper'));
    root.render(<MainApplication />);
};

initializeApp().then(renderApp);

ReactVaporDefaults.MODAL_ROOT = '#admin';
ReactVaporDefaults.DROP_ROOT = '#admin-drop-container';

(window as any).MonacoEnvironment = {
    getWorker: (workerId, label) => {
        if (label === 'json') {
            return new jsonWorker();
        }
        return new editorWorker();
    },
};
